import React, { Component } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import aboutMeImage from "../../assets/images/kucukharf_1377204315_31.jpeg";

class About extends Component {
  render() {
    return (
      <div>
        <section className="container fadeInUp photo">
          <Helmet>
            <meta charSet="utf-8" />
            <title>ba | about</title>
          </Helmet>
          <div className="col-6-of-12">
            <img alt="asdasd" src={aboutMeImage} />
          </div>
          <div className="col-4-of-12 pull-2">
            <h1>About Me</h1>
            <h2> Full-Stack Developer</h2>
          </div>
        </section>
        <section className="container fadeInUp profile">
          <div className="col-5-of-12">
            <h4>Profile</h4>
          </div>
          <div className="col-7-of-12 link-border">
            <p>
              an experienced Full-Stack developer who loves building web
              applications that are scalable, production-ready, and fully
              functional from the ground up. Over the past 10+ years, I have
              worked with both well-established firms and start-ups, gaining a
              wide range of expertise in web applications.
            </p>
            <p>
              I have experience in interface design, prototyping, wireframing,
              branding, and enjoy building digital products with scalable
              architecture on any cloud. As a software architect, I have led
              teams, managed projects, conducted technical evaluations, and
              performed technical interviews. My core competencies include
              creating flexible and scalable software architectures for
              applications, writing rock-solid code that adheres to best
              principles, and leading teams to success.
            </p>
            <p>
              Past two years of my career working full-time at Lugath. I live in
              Istanbul, Turkey.
            </p>
            <p>
              Feel free to <a href="/contact">reach out</a> just to say hello!{" "}
            </p>
          </div>
        </section>
        <section className="container fadeInUp profile">
          <div className="col-5-of-12">
            <h4>Experience</h4>
          </div>
          <div className="col-7-of-12 link-border">
            <ul className="experience">
              <li>
                <h4>
                  <span>Lugath Corp.</span>
                  <span>2021-Present</span>
                </h4>
                <span>Co-Founder / CTO / Software Architect</span>
              </li>
              <li>
                <h4>
                  <span>Sony Corp.</span>
                  <span>2016–2021</span>
                </h4>
                <span>Expert Software Developer / Competency Lead</span>
              </li>
              <li>
                <h4>
                  <span>Gri Creative</span>
                  <span>2014–2016</span>
                </h4>
                <span> Software Developer</span>
              </li>
              <li>
                <h4>
                  <span>Casaba Creative</span>
                  <span>2014–2015</span>
                </h4>
                <span>Software Developer</span>
              </li>
              <li>
                <h4>
                  <span>Personal</span>
                  <span>2005–2021</span>
                </h4>
                <span>Software Developer</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(About);
