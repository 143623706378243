import React, { Component } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const initialState = {
  name: "",
  email: "",
  message: "",
  baseClass: ["button", "disabled"],
  to: "burakarslan17@gmail.com",
  rules: [false, false, false],
  isFormValid: false,
  isEmailValid: null,
  isNameValid: null,
  isMessageValid: null,
  isLoading: false,
  isSent: false,
  responseStatus: "",
  responseMessage: "",
};
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  sendFormActions = (data) => {
    emailjs
      .send(
        "service_hxg2uy9",
        "template_nsgon1c",
        data,
        "user_MruV3GmX8VCj0noYw2Pn9"
      )
      .then(
        (result) => {
          console.log("data, result.text", data, result.text);
          this.setState({
            ...initialState,
            ...{
              isSent: true,
              isLoading: false,
              responseStatus: "success",
              responseMessage:
                "Thank you, I will be back asap to your message.",
            },
          });
          setTimeout(() => {
            this.setState({
              ...initialState,
              ...{
                isSent: false,
                responseStatus: "",
                responseMessage: "",
              },
            });
          }, 5000);
        },
        (error) => {
          console.log(error.text);
          this.setState({
            ...this.state,
            ...{
              isSent: true,
              isLoading: false,
              responseStatus: "error",
              responseMessage: error.text,
            },
          });
        }
      );
  };

  handleSubmit(event) {
    event.preventDefault();
    this.state.isFormValid && this.sendForm();
  }

  isFormValid() {
    let isFormValid = false;
    let isEmailValid = this.validateEmail(this.state.email);
    let isNameValid = this.state.name.length > 1 ? true : false;
    let isMessageValid = this.state.message.length > 1 ? true : false;

    this.setState(
      {
        rules: [isEmailValid, isNameValid, isMessageValid],
        isEmailValid: isEmailValid,
        isNameValid: isNameValid,
        isMessageValid: isMessageValid,
      },
      function () {
        isFormValid = !this.state.rules.includes(false);
        isFormValid &&
          this.setState({ isFormValid: true }, () => this.updateBtnClassName());
        !isFormValid && this.showMessage();
      }
    );
  }

  sendForm() {
    const data = {
      to_name: this.state.to,
      from_name: this.state.name,
      message: this.state.message,
      reply_to: this.state.email,
    };

    this.setState(
      {
        ...this.state,
        ...{ isLoading: true },
      },
      () => {
        this.updateBtnClassName();
        this.sendFormActions(data);
      }
    );
  }

  showMessage() {
    this.setState({ isFormValid: false });
  }

  updateBtnClassName() {
    let baseClass = this.state.baseClass;

    if (this.state.isFormValid) {
      baseClass = baseClass.filter((c) => c !== "disabled");
    } else {
      !baseClass.includes("disabled") && baseClass.push("disabled");
    }

    if (this.state.isLoading) {
      !baseClass.includes("is-loading") && baseClass.push("is-loading");
    } else {
      baseClass = baseClass.filter((c) => c !== "is-loading");
    }

    console.log(baseClass.join(" "));

    this.setState({
      ...this.state,
      ...{
        baseClass: baseClass,
      },
    });
  }

  getBtnClassName() {
    const baseClass = this.state.baseClass;
    return baseClass.join(" ") || "";
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    this.isFormValid();
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    return (
      <section className="contact container fadeInUp small">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ba | contact</title>
        </Helmet>
        <div className="col-1-of-1 link-border">
          <h1>Let's Talk</h1>
          <h2>
            Reach out to say hello! My personal email is{" "}
            <a href="mailto:burakarslan17@gmail.com">burakarslan17@gmail.com</a>
          </h2>
        </div>
        <div className={`message-wrapper ${this.state.responseStatus}`}>
          {this.state.isSent ? this.state.responseMessage : ""}
        </div>
        <form className="contact-form grid-row">
          <fieldset className="col-1-of-2">
            <label>
              Full Name
              <span />
            </label>
            <input
              id="name"
              value={this.state.name}
              onChange={this.handleChange}
              placeholder="John Doe"
            />
            <span
              className={
                this.state.isNameValid ? "message hide" : "message show"
              }
            >
              {" "}
              Upps! is your name correct?{" "}
            </span>
          </fieldset>
          <fieldset className="col-1-of-2">
            <label>
              Email
              <span />
            </label>
            <input
              type="email"
              id="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="john.doe@gmail.com"
            />
            <span
              className={
                this.state.isEmailValid ? "message hide" : "message show"
              }
            >
              {" "}
              Upps! is your mail correct?{" "}
            </span>
          </fieldset>
          <fieldset className="col-1-of-1">
            <label>
              Message
              <span />
            </label>
            <textarea
              id="message"
              value={this.state.message}
              onChange={this.handleChange}
              placeholder="Hello..."
            />
            <span
              className={
                this.state.isMessageValid ? "message hide" : "message show"
              }
            >
              {" "}
              Upps! your message seems to strange.{" "}
            </span>
          </fieldset>
          <fieldset className="col-1-of-1">
            <div className="grid-row">
              <button
                type="button"
                onClick={this.handleSubmit}
                className={this.getBtnClassName()}
              >
                <span>
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#fff"
                  >
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(1 1)" strokeWidth="2">
                        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </g>
                    </g>
                  </svg>
                </span>
                {this.state.isLoading ? "Sending" : "Send Message"}
              </button>
            </div>
          </fieldset>
        </form>
      </section>
    );
  }
}

export default Contact;
